"use client";

import { stringDeburr } from "@zerodep/string-deburr";
import { type User } from "lucia";
import { type ReactNode, createContext, useContext, useRef } from "react";
import { type z } from "zod";
import { createStore } from "zustand";

import { removeNullsAndUndefined } from "~/lib/array/filter";
import { removeFancyQuotes } from "~/lib/strings";
import { updateHiddenLanguages } from "~/server/actions/user";
import {
  type Word2TaskInsertSchema,
  type Word2TaskSelectSchema,
} from "~/server/zod/tasks";
import { type ImageItem, type WordItem } from "~/server/zod/word2";

import { type TaskProps } from "../shared";

interface Answer {
  id: string;
  data: Record<string, string | number | boolean>;
}
export type ViewName = "SPELL" | "QUIZ" | "EXAM";

export type WordItemWithColumnId = WordItem & { columnId: string };
type ImageItemWithColumnId = ImageItem & { columnId: string };

export function isWordItem(
  item: ItemWithColumnId,
): item is WordItemWithColumnId {
  return item.type === "word";
}

export function isImageItem(
  item: ItemWithColumnId,
): item is ImageItemWithColumnId {
  return item.type === "image";
}

type ItemWithColumnId = WordItemWithColumnId | ImageItemWithColumnId;

export interface LeftRightPair {
  id: string;
  left: ItemWithColumnId[];
  right: ItemWithColumnId[];
}

type Word2TaskSchema = Word2TaskSelectSchema | Word2TaskInsertSchema;

interface Word2TaskProps {
  _task: Word2TaskSchema & { subject: string };
  answers: Record<string, Answer>;
  view?: ViewName;
  flipped: boolean;
  deburred: boolean;
  preferVoiceOnly: boolean;
  ignoreWhiteSpace: boolean;
  pickedWords: string[];
  hiddenLanguages: string[];
}

export interface Word2TaskState extends Word2TaskProps {
  setAnswer: (answer: Answer) => void;
  setView: (view: Word2TaskProps["view"]) => void;
  resetStore: () => void;
  setFlipped: (flipped: boolean) => void;
  setDeburred: (deburred: boolean) => void;
  setPreferVoiceOnly: (preferVoiceOnly: boolean) => void;
  setIgnoreWhiteSpace: (ignoreWhiteSpace: boolean) => void;
  setPickedWords: (pickedWords: string[]) => void;
  setHiddenLanguages: (hiddenLanguages: string[]) => void;
  preview: boolean;
  isExam: boolean;
  taskInstance: Props["taskInstance"];
  leftColumnIds: string[];
  setLeftColumnIds: (leftColumnsIds: string[]) => void;
  rightColumnIds: string[];
  setRightColumnIds: (rightColumnsIds: string[]) => void;
  computed: {
    canVoiceOnly: boolean;
    voiceOnly: boolean;
    task: {
      pairs: LeftRightPair[];
      leftColumnIds: string[];
      leftTitle: string;
      rightColumnIds: string[];
      rightTitle: string;
      columns: Word2TaskSchema["data"]["columns"];
    };
  };
}

interface Word2TaskContext {
  taskInstance: Props["taskInstance"];
  preview?: boolean;
  task: Word2TaskSchema & { subject: string };
  isExam?: boolean;
  user: User;
}

type Word2TaskStore = ReturnType<typeof createWord2TaskStore>;

const createWord2TaskStore = (ctx: Word2TaskContext) => {
  const DEFAULT_PROPS: Omit<Word2TaskProps, "_task"> = {
    answers: {},
    view: undefined,
    deburred: false,
    preferVoiceOnly: false,
    flipped: false,
    ignoreWhiteSpace: false,
    pickedWords: [] as string[],
    // TODO: Get from context
    hiddenLanguages: ctx.user.hiddenLanguages,
  };
  const leftColumnId = ctx.task.data.columns.find(
    (column) => column.canBeLeft,
  )?.id;
  const rightColumnId = ctx.task.data.columns.find(
    (column) => column.canBeRight && column.id !== leftColumnId,
  )?.id;
  return createStore<Word2TaskState>()((set, get) => ({
    ...DEFAULT_PROPS,
    _task: ctx.task,
    preview: ctx.preview ?? false,
    isExam: ctx.isExam ?? false,
    taskInstance: ctx.taskInstance,
    leftColumnIds: leftColumnId ? [leftColumnId] : [],
    setLeftColumnIds: (leftColumnsIds) =>
      set((state) => ({ ...state, leftColumnIds: leftColumnsIds })),
    rightColumnIds: rightColumnId ? [rightColumnId] : [],
    setRightColumnIds: (rightColumnsIds) =>
      set((state) => ({ ...state, rightColumnIds: rightColumnsIds })),
    setAnswer: (answer) =>
      set((state) => {
        const newData = { answers: { ...state.answers, [answer.id]: answer } };
        return newData;
      }),
    setView: (view) =>
      set((state) => ({ _task: state._task, view, answers: {} })),
    setFlipped: (flipped) => set((state) => ({ ...state, flipped })),
    setDeburred: (deburred) => set((state) => ({ ...state, deburred })),
    setPreferVoiceOnly: (preferVoiceOnly) =>
      set((state) => ({ ...state, preferVoiceOnly })),
    setIgnoreWhiteSpace: (ignoreWhiteSpace) =>
      set((state) => ({ ...state, ignoreWhiteSpace })),
    setPickedWords: (pickedWords) =>
      set((state) => ({ ...state, pickedWords })),
    resetStore: () =>
      set((state) => ({ ...DEFAULT_PROPS, _task: state._task })),
    setHiddenLanguages: (hiddenLanguages) => {
      set((state) => ({ ...state, hiddenLanguages }));
      void updateHiddenLanguages({ hiddenLanguages });
    },
    computed: {
      get canVoiceOnly() {
        const task = get().computed.task;
        if (task.leftColumnIds.length === 0) {
          return false;
        }
        const canVoiceOnly = task.pairs.every((pair) => {
          const leftColumnHasVoices = task.leftColumnIds.some((id) => {
            const column = ctx.task.data.columns.find(
              (column) => column.id === id,
            );
            return column?.type === "word" && column.voices.length > 0;
          });
          return (
            pair.left.some((item) => item.type === "word") &&
            leftColumnHasVoices
          );
        });
        return canVoiceOnly;
      },
      get voiceOnly() {
        const preferVoiceOnly = get().preferVoiceOnly;
        if (!preferVoiceOnly) {
          return false;
        }
        return get().computed.canVoiceOnly;
      },
      get task() {
        const leftColumnIds = get().leftColumnIds;
        const rightColumnIds = get().rightColumnIds;
        const data = get()._task.data;
        const flipped = get().flipped;
        const deburred = get().deburred;
        const _leftTitle = ctx.task.data.columns
          .filter((column) => leftColumnIds.includes(column.id))
          .map((column) => column.label)
          .join(" + ");
        const _rightTitle = ctx.task.data.columns
          .filter((column) => rightColumnIds.includes(column.id))
          .map((column) => column.label)
          .join(" + ");
        const leftTitle = !flipped ? _leftTitle : _rightTitle;
        const rightTitle = !flipped ? _rightTitle : _leftTitle;
        const pairs: LeftRightPair[] = data.rows
          .map((row) => {
            const map = (id: string) => {
              const column = data.columns.find((column) => column.id === id);
              if (!column) return;
              const item = row.items[id];
              if (!item) return;
              if (item.type === "word" && deburred) {
                const word = removeFancyQuotes(stringDeburr(item.word));
                return {
                  ...item,
                  columnId: column.id,
                  word,
                };
              }
              return { ...item, columnId: column.id };
            };
            const _left = removeNullsAndUndefined(leftColumnIds.map(map));
            const _right = removeNullsAndUndefined(rightColumnIds.map(map));
            return {
              id: row.id,
              left: flipped ? _right : _left,
              right: flipped ? _left : _right,
            };
          })
          .filter((pair) => pair.left.length > 0 && pair.right.length > 0);
        return {
          leftTitle,
          leftColumnIds: flipped ? rightColumnIds : leftColumnIds,
          rightTitle,
          rightColumnIds: flipped ? leftColumnIds : rightColumnIds,
          pairs,
          columns: data.columns,
        };
      },
    },
  }));
};

type Schema =
  | TaskProps<z.infer<typeof Word2TaskSelectSchema>>
  | TaskProps<z.infer<typeof Word2TaskInsertSchema>>;

export type Props = Schema & {
  isExam?: boolean;
};

export const Word2TaskContext = createContext<Word2TaskStore | null>(null);

export function Provider({
  children,
  ctx,
}: {
  children: ReactNode;
  ctx: Word2TaskContext;
}) {
  const store = useRef(createWord2TaskStore(ctx)).current;
  return (
    <Word2TaskContext.Provider value={store}>
      {children}
    </Word2TaskContext.Provider>
  );
}

export function useWord2TaskStore() {
  const Store = useContext(Word2TaskContext);
  if (!Store) throw new Error("Missing Word2TaskContext.Provider in the tree");
  return Store;
}
