"use client";

import Image from "next/image";
import { useState } from "react";

import { Skeleton } from "~/components/ui/skeleton";
import { getBlurData } from "~/lib/blurhash";
import { cn } from "~/lib/utils";
import { type ImageItem } from "~/server/zod/word2";

interface Props {
  item: ImageItem;
  height: number;
  width: number;
  alt?: string;
  className?: string;
}

export function RenderWord2Image({
  item,
  height,
  width,
  alt,
  className,
}: Props) {
  const [isLoading, setIsLoading] = useState(!item.blurhash);

  const extraprops = item.blurhash
    ? ({
        placeholder: "blur",
        blurDataURL: getBlurData(item.blurhash),
      } as const)
    : {};

  return (
    <div className="relative" style={{ aspectRatio: `${width} / ${height}` }}>
      {isLoading && (
        <Skeleton
          className={`absolute inset-0 ${isLoading ? "opacity-100" : "opacity-0"} transition-opacity ease-in-out`}
        />
      )}
      <Image
        src={`/static/word2-image/${item.image}`}
        style={{ aspectRatio: `${width} / ${height}` }}
        alt={alt ?? ""}
        width={width}
        height={height}
        onLoadingComplete={() => setIsLoading(false)}
        className={cn(
          "object-cover transition-opacity duration-300 ease-in-out",
          isLoading ? "opacity-0" : "opacity-100",
          className,
        )}
        {...extraprops}
      />
    </div>
  );
}
