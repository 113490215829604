"use client";

import shuffle from "fisher-yates";
import { useState } from "react";
import { useWizard } from "react-use-wizard";
import { useStore } from "zustand";

import { triggerConfetti } from "~/components/confetti";
import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";

import { NEXT_STEP_DELAY } from "./config";
import { type LeftRightPair, useWord2TaskStore } from "./provider";
import { RenderWord2Image } from "./render-word2-image";

export function QuizStep({ item }: { item: LeftRightPair }) {
  const Store = useWord2TaskStore();
  const task = useStore(Store, (s) => s.computed.task);
  const setAnswer = useStore(Store, (s) => s.setAnswer);
  const { nextStep } = useWizard();
  const [options, setOptions] = useState(() => {
    const wrongAnswerItems = shuffle(
      task.pairs.filter((w) => w.id !== item.id),
    ).slice(0, 3);
    const pairs = shuffle([item, ...wrongAnswerItems]);
    return pairs
      .map((pair) => ({
        id: pair.id,
        item: pair.right[0]!,
        correct: pair.id === item.id,
        guessed: false,
      }))
      .map((pair) => {
        if (pair.item.type !== "word") {
          return pair;
        }
        return {
          ...pair,
          item: {
            ...pair.item,
            word: shuffle(pair.item.word.split("\n"))[0]!,
          },
        };
      });
  });

  function handleGuess(index: number) {
    const newOptions = [...options];
    const option = newOptions[index];
    if (!option) {
      return;
    }
    option.guessed = true;
    setOptions(newOptions);
    if (option.correct) {
      triggerConfetti();
      setTimeout(() => {
        setAnswer({
          id: item.id,
          data: {
            answer:
              option.item.type === "word"
                ? option.item.word
                : `image:${option.item.image}`,
            errors: newOptions.filter((o) => o.guessed && !o.correct).length,
          },
        });
        void nextStep();
      }, NEXT_STEP_DELAY);
    }
  }

  const hasGuessedCorrect = options.some((o) => o.guessed && o.correct);

  return (
    <div
      className="container grid grid-cols-2 gap-x-2 gap-y-4"
      data-testid="quiz-options"
    >
      {options.map((option, i) => {
        const variant = (() => {
          if (option.guessed && option.correct) {
            return "success";
          }
          if (option.guessed && !option.correct) {
            return "destructive";
          }
          return "outline";
        })();
        return (
          <Button
            key={i}
            type="button"
            disabled={option.guessed || hasGuessedCorrect}
            variant={variant}
            className={cn(
              "w-full transition-opacity duration-1000 disabled:pointer-events-none",
              {
                "disabled:opacity-0": option.guessed && !option.correct,
                "disabled:opacity-100": option.guessed && option.correct,
                "h-64": option.item.type === "image",
              },
            )}
            onClick={() => {
              handleGuess(i);
            }}
          >
            {option.item.type === "word" ? (
              option.item.word
            ) : (
              <RenderWord2Image
                item={option.item}
                alt="Image option"
                width={600 / 2}
                height={400 / 2}
                className="mx-auto max-w-full object-contain p-2"
              />
            )}
          </Button>
        );
      })}
    </div>
  );
}
