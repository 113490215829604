"use client";

import { EyeOffIcon } from "lucide-react";
import { useMemo } from "react";
import { useStore } from "zustand";

import { Translation } from "~/components/translations/component";
import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { cn } from "~/lib/utils";
import { type Column } from "~/server/zod/word2";

import { useWord2TaskStore } from "./provider";
import { RenderWord2Image } from "./render-word2-image";
import { Word2ColumnEmoji } from "./use-task-languages";

const SUBJECTS_WITH_HIDDEN_LANGUAGES = ["SFI"];

export function WordTable() {
  const Store = useWord2TaskStore();
  const {
    pickedWords,
    setPickedWords,
    task,

    hiddenLanguages,
    setHiddenLanguages,
  } = useStore(Store, (s) => ({
    pickedWords: s.pickedWords,
    setPickedWords: s.setPickedWords,
    task: s._task,
    hiddenLanguages: s.hiddenLanguages,
    setHiddenLanguages: s.setHiddenLanguages,
  }));

  const allChecked = useMemo(() => {
    if (task.data.rows.every((row) => pickedWords.includes(row.id))) {
      return true;
    }
    if (pickedWords.length === 0) {
      return false;
    }
    return "indeterminate";
  }, [pickedWords, task.data]);

  function hiddenColumn(column: Column) {
    if (!SUBJECTS_WITH_HIDDEN_LANGUAGES.includes(task.subject)) {
      return false;
    }
    if (column.type !== "word") {
      return false;
    }
    return hiddenLanguages.includes(column.language);
  }

  return (
    <Table className="w-full table-fixed">
      <TableHeader>
        <TableRow className="bg-secondary">
          <TableHead className="w-8">
            <div className="items-top flex">
              <Checkbox
                id="select-all-words"
                aria-labelledby="select-all-words-label"
                checked={allChecked}
                onCheckedChange={(state) => {
                  setPickedWords(
                    state ? task.data.rows.map((row) => row.id) : [],
                  );
                }}
              />
              <label
                htmlFor="select-all-words"
                id="select-all-words-label"
                className="sr-only"
              >
                <Translation id="task.word2.table.select-all-words">
                  Välj alla ord
                </Translation>
              </label>
            </div>
          </TableHead>
          {task.data.columns.map((column) => {
            if (hiddenColumn(column)) {
              return null;
            }
            return (
              <TableHead
                key={column.id}
                className={cn(
                  "text-foreground",
                  column.type === "image" ? "w-40" : "w-48",
                )}
              >
                <div className="flex flex-row items-center gap-2 whitespace-nowrap font-medium">
                  <Word2ColumnEmoji column={column} className="h-5 w-5" />
                  {column.label}
                  {column.type !== "image" &&
                    SUBJECTS_WITH_HIDDEN_LANGUAGES.includes(task.subject) && (
                      <Button
                        size="icon-sm"
                        variant="ghost"
                        className="h-4 w-4"
                        onClick={() => {
                          setHiddenLanguages([
                            ...hiddenLanguages,
                            column.language,
                          ]);
                        }}
                      >
                        <EyeOffIcon aria-hidden="true" />
                        <span className="sr-only">
                          <Translation
                            id="task.word2.table.hide-column"
                            replace={{ column: column.label }}
                          >
                            {"Dölj kolumn ({column})"}
                          </Translation>
                        </span>
                      </Button>
                    )}
                </div>
              </TableHead>
            );
          })}
        </TableRow>
      </TableHeader>
      <TableBody>
        {task.data.rows.map((row) => (
          <TableRow key={row.id}>
            <TableCell>
              <div className="items-top flex">
                <Checkbox
                  id={`checkbox-${row.id}`}
                  aria-labelledby={`checkbox-${row.id}-label`}
                  checked={pickedWords.includes(row.id)}
                  onCheckedChange={(state) => {
                    if (state) {
                      setPickedWords([...pickedWords, row.id]);
                    } else {
                      setPickedWords(pickedWords.filter((id) => id !== row.id));
                    }
                  }}
                />
                <label
                  htmlFor={`checkbox-${row.id}`}
                  id={`checkbox-${row.id}-label`}
                  className="sr-only"
                >
                  <Translation
                    id="task.word2.table.select-word"
                    replace={{ word: row.id }}
                  >
                    {"Välj ord ({word})"}
                  </Translation>
                </label>
              </div>
            </TableCell>
            {task.data.columns.map((column) => {
              if (hiddenColumn(column)) {
                return null;
              }
              const item = row.items[column.id];
              if (!item) {
                return <TableCell key={column.id} />;
              }
              return (
                <TableCell key={column.id}>
                  {item?.type === "image" ? (
                    <RenderWord2Image
                      item={item}
                      alt="Bild"
                      width={600 / 3}
                      height={400 / 3}
                    />
                  ) : (
                    <span className="block whitespace-pre-line">
                      {item.word}
                    </span>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
